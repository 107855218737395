import React from 'react'
import { SEO } from '../components'
import { GetStarted } from '../routes'

const GetStartedPage = () => {
  return (
    <>
      <SEO
        title={'Get Started'}
        withoutTitleTemplate
        description={
          'Book your free consultation and get the clinical insight you need.'
        }
      />
      <GetStarted />
    </>
  )
}

export default GetStartedPage
